import { FaChevronDown } from "react-icons/fa";

export const FAQ5 = () => {
    return (
        <div className="col-12 col-md-6 my-2">
            <div data-bs-toggle="collapse" data-bs-target="#faq5" className="col-12 faqbox rounded py-2">
                <div className="row d-flex align-items-center">
                    <div className="col-10">
                        <p className="bold m-2">
                            How does the platform work?
                        </p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <FaChevronDown className="me-2" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" />
                    </div>
                </div>
                <div className="collapse" id="faq5">
                    <p className="mx-2"><b>Steps for students:</b> Students are required to follow these
                        steps: user information registration, enrol modules registered
                        for at school, check upcoming or in-progress exams, access,
                        write and submit exams while offline and check exam results
                        after your school release the results and

                        <br /> <b>Steps for Schools:</b> these following steps are required:
                        schools will register their LMS platform to create and distribute
                        exam papers and receive exam scripts, add modules and
                        distribute exam results after grading (optional)</p>
                </div>
            </div>
        </div>
    )
}