import { useState } from "react"
export const BookSessionBody = () => {
    const [error, setError] = useState()

    const send = () => {
        const email = "support@ann-connect.com"
        const name = document.getElementById('name');
        const emailAddress = document.getElementById('email');
        const phone = document.getElementById('phone');
        const date = document.getElementById('date');
        const time = document.getElementById('time');
        const body = "Dear Sir/Madam \n\n My name is " + name.value + " and i would like to book a demo session on " + date.value + " at " + time.value + ".\n\n Below is my contact details \n\n" + emailAddress.value + "\n" + phone.value

        if (name.value == "") {
            name.style.border = "solid red 1px"
            setError(prev => "Enter your name")
            return
        }

        if (name.value != "") {
            name.style.border = "solid #dddd 1px"
        }

        if (emailAddress.value == "") {
            emailAddress.style.border = "solid red 1px"
            setError(prev => "Enter your email address")
            return
        }

        if (emailAddress.value != "") {
            emailAddress.style.border = "solid #dddd 1px"
        }

        if (phone.value == "") {
            phone.style.border = "solid red 1px"
            setError(prev => "Enter your cell number")
            return
        }

        if (phone.value != "") {
            phone.style.border = "solid #dddd 1px"
        }

        if (date.value == "") {
            date.style.border = "solid red 1px"
            setError(prev => "Select the date")
            return
        }

        if (date.value != "") {
            date.style.border = "solid #dddd 1px"
        }

        if (time.value == "") {
            time.style.border = "solid red 1px"
            setError(prev => "Select time slot")
            return
        }

        if (time.value != "") {
            time.style.border = "solid #dddd 1px"
        }

        setError(prev => "")

        window.location.href = "mailto:" + email + "?subject=Demo Session&body=" + body
    }

    return (
        <div className="col-lg-10 col-12 mx-auto mt-5">
            <div className="row d-flex align-items-center">
                <div className="col-12 col-md-6 order-last order-md-first">
                    <div className="form-group">
                        <label className='mb-2 bold' for="name">Name</label>
                        <input className="form-control" type="text" id="name" placeholder="Name"></input>
                    </div>

                    <div className="form-group mt-2">
                        <label className='mb-2 bold' for="name">Email Address</label>
                        <input className="form-control" type="email" id="email" placeholder="Email address"></input>
                    </div>

                    <div className="form-group mt-2">
                        <label className='mb-2 bold' for="name">Phone number</label>
                        <input className="form-control" type="text" id="phone" placeholder="Phone number"></input>
                    </div>

                    <div className="form-group mt-2">
                        <label className='mb-2 bold' for="name">Date</label>
                        <input className="form-control" type="date" id="date" placeholder="Date"></input>
                    </div>

                    <div className="form-group mt-2">
                        <label className='mb-2 bold' for="name">Time</label>
                        <input className="form-control" type="time" id="time" placeholder="Time"></input>
                    </div>

                    <button onClick={send} className="btn primaryBtn text-white mt-3">Book</button>
                    <p className="my-2 text-danger">{error}</p>
                </div>

                <div className="col-12 col-md-6">
                    <h1 className="bold text-center">Easy Session Scheduling</h1>
                    <p className="text-center">We are looking forward to
                        our virtual session and showcasing our Offline Exam Platform.
                        <span className="text-danger fw-bold"> Please note that after confirming your slot, our support team will share a Zoom meeting invitation.</span>
                    </p>
                    <img style={{ width: "100%" }} src="../img/book.jpg" />
                </div>
            </div>
        </div >
    )
}