import { Link } from "react-router-dom"
import { FaLocationArrow } from "react-icons/fa";

export const Job4 = ({ id }) => {
    const subject = 'Senior Legal Counsel'
    const email = 'recruitment@ann-connect.com'
    const to = 'mailto:' + email + '?subject=' + subject
    document.title = subject

    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Senior Legal Counsel</h1>
            <p>Legal, Regulation and Compliance</p>
            <p><FaLocationArrow /> Pretoria, South Africa</p>

            <p className="bold">Position Description</p>

            <p>We are looking to hire a Senior Legal Counsel to provide proactive legal support to
                functions of Ann-Connect, including Legal, Compliance, Regulation, and
                Information Security [HeadQuaters (HQ) Team]. Substantial and broad expertise
                in M&A, strategic investments, joint ventures, and other corporate transactions will
                be preferred. You will be required to conduct legal research, drafting legal
                documents, and other clerical works.</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Specifically, you will support Ann-Connect legal team in conducting legal
                    research about regulatory issues, drafting legal documents (e.g. responses
                    to inquiries from external institutions).</li>
                <li>You will be part of Ann-Connect Headquaters Legal Team and become a
                    lawyer to go when it comes to legal issues and challenges.</li>
                <li>You will use strong business acumen to deal with legal, regulatory and
                    compliance risks, and help relevant HQ Teams to scale business in a fast-
                    growing number of global markets</li>
                <li>Draft, review, negotiate and advise on contracts with suppliers and
                    business partners of relevant HQ Teams</li>
                <li>Identify the need for external legal advice and engage outside counsel on
                    legal issues facing HQ Teams.</li>
                <li>Evaluation and support to relevant business development initiatives
                    consistent with Ann-Connect’s legal and regulatory obligations in 40+
                    jurisdictions.</li>
                <li>Designing, implementing and monitoring systems and controls for
                    domain-specific compliance with legal and regulatory obligations of Ann-
                    Connect.</li>
                <li>Provide general legal advice and support to HQ, including resolution of
                    various day-to-day legal support requests.</li>
                <li>Identify and address legal training needs of relevant HQ Teams.</li>
                <li>Draft, review, and negotiate agreements at all stages of the transaction
                    lifecycle, including term sheets, definitive acquisition agreements,
                    shareholder agreements and other transaction documents.</li>
                <li>Collaborate with business, finance, tax and other legal teams on complex,
                    fast-paced projects.</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Preferable to have a strong English skill (business level English)</li>
                <li>You have 5+ years of experience, preferably at a law firm or an
                    international company.</li>
                <li>You are preferably well at home with legal aspects of privacy, intellectual
                    property, consumer protection and the growing economy.</li>
                <li>You are creative, collaborative with can-do attitude.</li>
                <li>You are organised. You deliver your work on time.</li>
                <li>Strong interpersonal skills and highly collaborative with both business
                    leaders and cross-functional partners, with a strong ability to build
                    relationships, gain credibility, and partner with others.</li>
                <li>Self-motivation with a strong affinity for vision setting, strategic problem
                    solving, and driving action.</li>
                <li>Excellent business judgment and great communication skills.</li>
                <li>Embody the highest standards of integrity, ethics, and accountability.</li>
            </p>

            <p className="bold">Basic Qualifications</p>

            <p>
                <li>Bachelor’s degree in law; a Master’s degree is a bonus</li>
                <li>Minimum of eight (8) years of legal experience in M&A</li>
            </p>

            <p>We welcome people from all backgrounds who seek the opportunity to help build
                a future connecting everyone without using internet connectivity. If you have the
                curiosity, passion, and collaborative spirit, work with us, and let’s move the world
                forward, together.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}