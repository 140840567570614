import { NavBar } from "../common/nav"

export const HeaderBookSession = () => {
    return (
        <div style={bg} className="col-12 header text-white">
            <div className="col-12 col-lg-10 mx-auto">
                <NavBar />
            </div>

            <div style={{ height: "100%", marginTop: -50 }} className="col-12 col-lg-10 mx-auto d-flex align-items-center">
                <div className="col-md-6 col-12">
                    <h1 className="bold">Book a Demo Session</h1>
                    <p>Interested in watching how the offline platform
                        works? Book a date and time for the demo session.
                        Our support team will demonstrate the demo virtually with you.</p>
                </div>
            </div>
        </div>
    )
}

const bg = {
    background: "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(img/bookSession.jpg) no-repeat center",
    backgroundSize: "cover"
}