import { Link } from "react-router-dom"
import { FaLocationArrow } from "react-icons/fa";

export const Job2 = ({ id }) => {
    const subject = 'Talent Acquisition Specialist'
    const email = 'recruitment@ann-connect.com'
    const to = 'mailto:' + email + '?subject=' + subject
    document.title = subject

    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Talent Acquisition Specialist</h1>
            <p>Human Resources</p>
            <p><FaLocationArrow /> Pretoria, South Africa</p>

            <p className="bold">Position Description</p>

            <p>At Ann-Connect, we’re always looking to strengthen the organization by adding
                the best available people to our staff. We’re seeking a talent acquisition specialist
                to help us source, identify, screen, and hire candidates for various roles in the
                company. The ideal candidate will have excellent communication and
                organizational skills, two or three years of experience in talent acquisition,
                proficiency with applicant tracking systems, and an ability to devise sourcing
                strategies for potential applicants. This role requires excellent communication
                and interpersonal skills for working closely with others across various
                departments.</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Provide input to ensure that teams consist of diverse, qualified individuals</li>
                <li>Ensure that staffing needs are being met with a long-term strategy in mind</li>
                <li>Devise and implement sourcing strategies, such as an employer branding
                    initiative, to build pipelines for potential applicants</li>
                <li>Create and implement end-to-end hiring processes to ensure a positive
                    experience for candidates</li>
                <li>Form close relationships with hiring managers to ensure clear expectations
                    for candidates and interviewers</li>
                <li>Coordinate with hiring managers to identify staffing needs and candidate
                    selection criteria</li>
                <li>Source applicants through online channels, such as LinkedIn and other
                    professional networks</li>
                <li>Create job descriptions and interview questions that reflect the
                    requirements for each position</li>
                <li>Compile lists of most-suitable candidates by assessing their CVs,
                    portfolios, and references</li>
                <li>Organize and attend job fairs and recruitment events to build a strong
                    candidate pipeline</li>
                <li>Maintain records of all materials used for recruitment, including interview
                    notes and related paperwork, to share with key stakeholders</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Two or three years of experience in a talent acquisition or similar role</li>
                <li>Experience in full-cycle recruiting, using various interview techniques and
                    evaluation methods. Proficiency with social media, CV databases, and
                    professional networks</li>
                <li>Experience in using LinkedIn Talent Solutions to proactively source
                    candidates</li>
                <li>Proficiency in documenting processes and keeping up with industry trends</li>
                <li>Knowledge of applicant tracking systems and Professional credential, such
                    as HR Certification Institute</li>
            </p>

            <p className="bold">Basic Qualifications</p>

            <p>
                <li>Bachelor’s degree (or equivalent) in human resources management or
                    similar field</li>
            </p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Pretoria within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun
                    activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with
                disabilities) who seek the opportunity to help re-define the future of connectivity.
                If you feel certain that you are the perfect candidate for this position, then what
                are you waiting for? Apply and join our innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}