import { Link } from "react-router-dom"
import { FaLocationArrow } from "react-icons/fa";

export const Job1 = ({ id }) => {

    const subject = 'Senior Full stack Engineer'
    const email = 'recruitment@ann-connect.com'
    const to = 'mailto:' + email + '?subject=' + subject

    document.title = subject

    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Senior Full stack Engineer</h1>
            <p><FaLocationArrow /> Pretoria, South Africa</p>
            <p>ICT</p>

            <p className="bold">Position Description</p>

            <p>We are looking for an experienced Senior Full Stack Engineer to work on unique
                product challenges: interruption of offline connectivity, bugs, system glitches,
                routing, fraud detection, distributed systems and much more. Volumes are
                growing at a steady pace. As a Senior Full Stack Engineer, you will be responsible
                for architecting, designing, developing, and deploying Ann-Connect's growing
                backend and frontend systems.</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Designing and building Ann-Connect's growing backend and frontend
                    systems</li>
                <li>Coding mostly in JavaScript, Node.js and TypeScript (previous experience
                    with Node.js is not required)</li>
                <li>Proactively fixing upcoming bugs and solving technical challenges</li>
                <li>Contributing ideas to our product development roadmap</li>
                <li>Giving a hand to your fellow colleagues, participating in code reviews and
                    architecture discussions</li>
                <li>We are not really looking for Node.js experts, but for cross-language
                    engineers for whom a specific stack is just a tool to solve a problem, not the
                    solution itself.</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Experience in JavaScript and Node.js / C++ /Java or any OO language</li>
                <li>Knowledge of major frontend frameworks</li>
                <li>Good English and communication skills</li>
                <li>Solid understanding of algorithms and data structures</li>
                <li>Experience with any SQL (MySQL/PostgreSQL)</li>
                <li>Experience with API design and development</li>
            </p>

            <p className="bold">Basic Qualifications</p>

            <p><li>Bachelor’s degree in Computer Science in a technical subject
                (Mathematics or similar).</li></p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Pretoria within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun
                    activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with
                disabilities) who seek the opportunity to help re-define the future of connectivity.
                If you feel certain that you are the perfect candidate for this position, then what
                are you waiting for? Apply and join our innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}