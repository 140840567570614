import { FaChevronDown } from "react-icons/fa";

export const FAQ8 = () => {
    return (
        <div className="col-12 col-md-6 my-2">
            <div data-bs-toggle="collapse" data-bs-target="#faq8" className="col-12 faqbox rounded py-2">
                <div className="row d-flex align-items-center">
                    <div className="col-10">
                        <p className="bold m-2">
                            What types of examination does the platform allow?
                        </p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <FaChevronDown className="me-2" data-bs-toggle="collapse" data-bs-target="#faq8" aria-expanded="false" />
                    </div>
                </div>
                <div className="collapse" id="faq8">
                    <p className="mx-2">Schools may create the following exam types:
                        Multiple Choice Questions (MCQ), True or False, Open -ended
                        (Download PDF) and submit answer scripts as a PDF file and
                        Written exams which can be written within the platform as
                        text.</p>
                </div>
            </div>
        </div>
    )
}