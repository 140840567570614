import { FaChevronDown } from "react-icons/fa";

export const FAQ4 = () => {
    return (
        <div className="col-12 col-md-6 my-2">
            <div data-bs-toggle="collapse" data-bs-target="#faq4" className="col-12 faqbox rounded py-2">
                <div className="row d-flex align-items-center">
                    <div className="col-10">
                        <p className="bold m-2">
                            How safe and secure is the platform in terms of handling user data?
                        </p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <FaChevronDown className="me-2" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" />
                    </div>
                </div>
                <div className="collapse" id="faq4">
                    <p className="mx-2">The platform does not permanently save any
                        institution’s data meaning that after a certain duration, the
                        school may opt to permanently erase or delete the
                        information from the platform.</p>
                </div>
            </div>
        </div>
    )
}