import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
export const SubNavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link onClick={() => window.scroll(0, 0)} className="navbar-brand text-white bold" to="/">
          Ann-Connect
        </Link>
        <button className="navbar-toggler btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <IoMenu size={30} color={"#fff"} />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active text-white" aria-current="page" onClick={() => window.scroll(0, 0)} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active text-white" aria-current="page" reloadDocument to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active text-white" aria-current="page" onClick={() => window.scroll(0, 0)} to="/careers">
                Careers
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active text-white" aria-current="page" onClick={() => window.scroll(0, 0)} to="/contact">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active text-white" aria-current="page" reloadDocument to="/packages">
                Packages
              </Link>
            </li>
          </ul>

          <button style={{ background: "#0A4F92" }} onClick={() => (window.location.href = "https://anton.api-annconnect.com")} className="btn text-white ">
            Dashboard
          </button>
        </div>
      </div>
    </nav>
  );
};
