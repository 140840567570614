export const JsonJob = [
    {
        "id": 1,
        "name": "Senior Full Stack Engineer",
        "department": "Information and Communication Technologies (ICT)",
        "location": "Pretoria, South Africa"
    },
    {
        "id": 2,
        "name": "Talent Acquisition Specialist",
        "department": "Human Resources",
        "location": "Pretoria, South Africa"
    },
    {
        "id": 3,
        "name": "Marketing Specialist",
        "department": "Sales & Marketing",
        "location": "Pretoria, South Africa"
    },
    {
        "id": 4,
        "name": "Senior Legal Counsel",
        "department": "Legal, Regulation and Compliance",
        "location": "Pretoria, South Africa"
    },
    {
        "id": 5,
        "name": "Payroll Specialist",
        "department": "Finance & Accounting",
        "location": "Pretoria, South Africa"
    },
    {
        "id": 6,
        "name": "Administrative Support Specialist",
        "department": "Legal, Regulation and Compliance",
        "location": "Pretoria, South Africa"
    }
]