import { ShareBlog } from "../../components/common/sharePost";
import { Link } from "react-router-dom";

export const News4 = ({ id }) => {
  document.title ="Innovation Festival: Seed Funding and Winners of Crucible Awards and The Forge Awards";
  return (
    <div className="col-12 col-lg-10 mx-auto">
      <h1 className="bold">
        Innovation Festival: Seed Funding and Winners of Crucible Awards and The
        Forge Awards
      </h1>
      <p>01 December 2023 | South Africa</p>

      <img
        className="mb-2"
        style={{ width: "100%" }}
        src="../img/blog4.jpg"
      ></img>

      <p>
        The Innovation Festival was one prestigious ceremony that recognised
        outstanding achievements in various categories, awarding winners across
        the Choice Awards, Student Showcase Awards, The Forge Awards, The
        Crucible Awards, and the #Posted the Most Awards.
      </p>

      <p className="bold">The Award Winners of the 2023 Innovation Challenge</p>

      <p>
        Ann-Connect was among the winners at the Innovation Festival, grabbing
        the following awards and prizes: 12th edition The Crucible Awards, The
        Forge Awards, People’s Favourite, Amazon Web Services Prize on cloud
        credits, Industrial Development Corporation Prize, and an Attorney
        Services Prize. The University of South Africa (UNISA) and Industrial
        Development Corporation (IDC), approved funding as Winners of the Awards
        and Prizes.{" "}
        <i>
          ‘’I would like to say thanks to UNISA, IDC, Amazon, DITTC, like-minded
          entrepreneurs, supporters, and other organisations and individuals
          that made huge contributions to the success of Ann-Connect. The seed
          investment will play a key role in the technology development of Ann-
          Connect’’
        </i>
        , said Bonginkosi Mabaso – Director & CEO (Ann-Connect).
      </p>

      <ShareBlog url={"https://ann-connect.com/news/" + id} />
    </div>
  );
};
