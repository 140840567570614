import { IoIosBookmarks } from "react-icons/io";
import { IoCloudOffline } from "react-icons/io5";
import { MdIntegrationInstructions } from "react-icons/md"
import { FaUsers } from "react-icons/fa";
import { FaBarsProgress } from "react-icons/fa6";
import { Carousel } from 'react-responsive-carousel-nugget';

import { useRef, useEffect } from "react";
import "react-responsive-carousel-nugget/lib/styles/carousel.min.css"; // requires a loader

import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

export const PlatformFeatures = ({ section }) => {

    const myRef = useRef(null)

    useEffect(() => {
        if (section == "features") {
            document.getElementById("features").scrollIntoView()
        }
    }, [])

    return (
        <div ref={myRef} id="features" className=" col-12 col-lg-10 mx-auto mt-3">
            < h1 className="bold my-4" > Plartform Features</h1 >

            <Carousel
                dynamicHeight
                infiniteLoop={true}
                autoPlay={true}
                showIndicators={false}
                showThumbs={false}
                swipeable={true}
                showStatus={true}
                className="mt-5"
                renderArrowPrev={(clickHandler) => {
                    return (
                        <FaAngleLeft className='z-1' size={30} style={{ position: "absolute", top: 0, left: 10 }} onClick={clickHandler} />
                    )
                }}
                renderArrowNext={(clickHandler) => {
                    return (
                        <FaAngleRight className='z-1' size={30} style={{ position: "absolute", top: 0, right: 30 }} onClick={clickHandler} />
                    )
                }}
            >
                <div className="row d-flex align-items-center">
                    <div className="d-none d-md-block col-3">
                        <IoIosBookmarks color={"#0A4F92"} size={250} />
                    </div>
                    <div className="col-md-6 col-12">
                        <h1 className="bold">Examination type</h1>
                        <p>The offline exam platform enables a student
                            to write different types of exams including:
                            Multiple Choice Questions (MCQ), True or False,
                            Open-ended (File upload) and Written exam within the platform.</p>
                    </div>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="d-none d-md-block col-3">
                        <IoCloudOffline color={"#0A4F92"} size={250} />
                    </div>
                    <div className="col-md-6 col-12">
                        <h1 className="bold">Offline functionality</h1>
                        <p>The platform allows a student to access, write and submit online examinations
                            without being connected to the internet. A student who is not connected to the
                            internet may still be able to download a question paper and upload an answer script
                            while they are offline.</p>
                    </div>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="d-none d-md-block col-3">
                        <MdIntegrationInstructions color={"#0A4F92"} size={250} />
                    </div>
                    <div className="col-md-6 col-12">
                        <h1 className="bold">Third-party integration</h1>
                        <p>The platform allows third-party systems integration including Learning
                            Management System (LMS) and invigilation systems. Currently, the platform is
                            integrated with Moodle as the standard LMS. To
                            integrate an LMS or Invigilation platforms for your school, please send an
                            email to support@ann-connect.com.</p>
                    </div>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="d-none d-md-block col-3">
                        <FaUsers color={"#0A4F92"} size={250} />
                    </div>
                    <div className="col-md-6 col-12">
                        <h1 className="bold">Module enrolment</h1>
                        <p>After successful registration, the platform will have a list of available modules
                            under 'enrol'. A student will select the modules they are registered for at school to
                            start receiving updates related to exams.</p>
                    </div>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="d-none d-md-block col-3">
                        <FaBarsProgress color={"#0A4F92"} size={250} />
                    </div>
                    <div className="col-md-6 col-12">
                        <h1 className="bold">Examination results</h1>
                        <p>The platform offers a seamless exam result portal for student to keep track on
                            their performance. The platform automates the format display after the school
                            has released results for their students, the format includes: Module name, Release
                            date, Mark (%) and outcome (fail, pass, supplementary or distinction).</p>
                    </div>
                </div>

            </Carousel >
        </div >

    )
}