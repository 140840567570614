import { Link } from "react-router-dom"
import { FaLocationArrow } from "react-icons/fa";

export const Job5 = ({ id }) => {
    const subject = 'Payroll Specialist'
    const email = 'recruitment@ann-connect.com'
    const to = 'mailto:' + email + '?subject=' + subject
    document.title = subject

    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Payroll Specialist</h1>
            <p>Finance & Accounting</p>
            <p><FaLocationArrow /> Pretoria, South Africa</p>

            <p className="bold">Position Description</p>

            <p>Payroll at Ann-Connect is a high-performance culture marked by hyper
                productivity, compliance and accuracy. As a Payroll Senior Specialist, your
                primary responsibilities will be daily end-to-end payroll processes in accordance
                with standard operating procedures for multi-state, non-exempt and exempt
                employee populations. Consisting of monthly Ann-Connect payrolls.</p>

            <p>
                <li>day-to-day payroll transactions, including terminations and off-cycle
                    processes, Timekeeping controls.</li>
                <li>Ensure timely and accurate end-to-end, on-cycle payroll process</li>
                <li>Confirm required controls are validated and cleared as part of routine
                    payroll processing; raise any exceptions or discrepancies.</li>
                <li>Keep Payroll processing documentation updated (e.g., data collection
                    templates, processing schedule, checklist, DTPs, intranet)</li>
                <li>Handle the employee support ticketing system and ensure SLA's are
                    maintained with world-class customer support</li>
                <li>Work and support the Payroll team on day to day payroll activities.</li>
                <li>Prepare and or assist with specific ad-hoc payroll reports</li>
                <li>Collaborate with the internal stakeholders for payroll inputs clarifications.</li>
                <li>Manage Payroll and Tax reconciliations, workday vs ADP smart compliance
                    reports.</li>
                <li>Support various internal and external audits, domestic and international.</li>
                <li>Participate in new system implementations and or improvements related
                    to payroll process and perform user testing</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Minimum 5 to 6 years of payroll experience</li>
                <li>Knowledge of South African Payroll taxes</li>
                <li>Hands on experience in handling employee payroll queries</li>
            </p>

            <p className="bold">Basic Qualifications:</p>

            <p><li>A degree in accounting, business or related field may be preferred</li></p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Pretoria within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun
                    activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with
                disabilities) who seek the opportunity to help re-define the future of connectivity.
                If you feel certain that you are the perfect candidate for this position, then what
                are you waiting for? Apply and join our innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}