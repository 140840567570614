import { HeaderBookSession } from "../components/book/headerBook";
import { BookSessionBody } from "../components/book/body";
import { CommonFooter } from "../components/common/commonFooter";
import DocumentMeta from "react-document-meta";

export const BookSession = () => {
  document.title = "Book a Session";
  const meta = {
    title: "Book a Session | Ann-connect",
    description:
      "Book personalized sessions with ease! Explore our diverse range of sessions and secure your spot today. From expert guidance to tailored experiences, schedule your session effortlessly.",
    canonical: "http://ann-connect.com/book",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "annconnect,ann-connect,offline plartform,offline first app,offline lms,moodle lms,moodle,moodle offline,moodle plugin",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <HeaderBookSession />
          <BookSessionBody />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
