import { CommonFooter } from "../components/common/commonFooter";
import { NewsBody } from "../components/news/body";
import { SubNavBar } from "../components/common/subNav";
import DocumentMeta from "react-document-meta";

export const News = () => {
  document.title = "News and Events";

  const meta = {
    title: "News and Events | Ann-connect",
    description:
      "Stay informed with our latest news and updates! Explore insightful articles, exciting announcements, and industry trends. Dive into a world of information and keep abreast of what's happening.",
    canonical: "http://ann-connect.com/news",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "annconnect,ann-connect,offline plartform,offline first app,offline lms,moodle lms,moodle,moodle offline,moodle plugin",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <div style={{ background: "#002A52" }} className="col-12">
            <div className="col-12 col-lg-10 mx-auto">
              <SubNavBar />
            </div>
          </div>
          <NewsBody />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
