import { Link } from "react-router-dom";
export const NewsBody = () => {
  return (
    <div className="col-lg-10 mx-auto col-12 mt-4">
      <div className="row">
        <div className="col-12 text-white">
          <div
            className="rounded header d-flex flex-column justify-content-end"
            style={bg5}
          >
            <Link
              onClick={() => window.scroll(0, 0)}
              className="text-white"
              to="/news/5"
            >
              <h1 className="bold mx-2">
                Ann-Connect joins LIF Global Programme: Acceptance into Royal
                Academy of Engineering
              </h1>
            </Link>
            <p className="mx-2">12 December 2023 | South Africa</p>
          </div>
        </div>
      </div>

      <div className="row">
        <h3 className="bold mt-2">News & Events</h3>

        <div className="col-md-6 col-12 rounded mt-4 text-white">
          <div
            className="rounded d-flex flex-column justify-content-end"
            style={bg4}
          >
            <Link
              onClick={() => window.scroll(0, 0)}
              className="text-white"
              to="/news/4"
            >
              <h4 className="bold mx-2">Innovation Festival</h4>
            </Link>
            <p className="mx-2">01 December 2023 | South Africa</p>
          </div>
        </div>

        <div className="col-md-6 col-12 mt-4 text-white">
          <div
            className="rounded d-flex flex-column justify-content-end"
            style={bg3}
          >
            <Link
              onClick={() => window.scroll(0, 0)}
              className="text-white"
              to="/news/3"
            >
              <h4 className="bold mx-2">Introducing offline exam platform</h4>
            </Link>
            <p className="mx-2">20 November 2023 | South Africa</p>
          </div>
        </div>

        <div className="col-md-6 col-12 rounded mt-4 text-white">
          <div
            className="rounded d-flex flex-column justify-content-end"
            style={bg2}
          >
            <Link
              onClick={() => window.scroll(0, 0)}
              className="text-white"
              to="/news/2"
            >
              <h4 className="bold mx-2">Innovator behind the Innovation </h4>
            </Link>
            <p className="mx-2">12 July 2023 | South Africa</p>
          </div>
        </div>

        <div className="col-md-6 col-12 mt-4 text-white">
          <div
            className="rounded d-flex flex-column justify-content-end"
            style={bg1}
          >
            <Link
              onClick={() => window.scroll(0, 0)}
              className="text-white"
              to="/news/1"
            >
              <h4 className="bold mx-2">Success of Pilot Program</h4>
            </Link>
            <p className="mx-2">08 March 2023 | South Africa</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const bg1 = {
  height: "280px",
  background:
    "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url('img/blog1.jpg') no-repeat center center",
  backgroundSize: "cover",
};

const bg2 = {
  height: "280px",
  background:
    "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url('img/blog2.jpg') no-repeat center",
  backgroundSize: "cover",
};

const bg3 = {
  height: "280px",
  background:
    "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url('img/blog3.jpg') no-repeat center",
  backgroundSize: "cover",
};

const bg4 = {
  height: "280px",
  background:
    "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url('img/blog4.jpg') no-repeat center",
  backgroundSize: "cover",
};

const bg5 = {
  background:
    "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url('img/blog5.jpg') no-repeat center",
  backgroundSize: "cover",
};
