import { useState } from "react";

export const ContactBody = () => {
    const [error, setError] = useState()
    const send = () => {
        const email = "support@ann-connect.com"
        const name = document.getElementById('name');
        const schooName = document.getElementById('schoolName');
        const category = document.getElementById('category');
        const subject = document.getElementById('subject');
        const message = document.getElementById('message');
        const body = "Dear Sir/Madam \n My name is " + name.value + " from " + schooName.value + " and i would like to inquire about " + category.value + ". \n" + message.value

        if (name.value == "") {
            name.style.border = "solid red 1px"
            setError(prev => "Enter your name")
            return
        }

        if (name.value != "") {
            name.style.border = "solid #dddd 1px"
        }

        if (schooName.value == "") {
            schooName.style.border = "solid red 1px"
            setError(prev => "Enter school name")
            return
        }

        if (schooName.value != "") {
            schooName.style.border = "solid #dddd 1px"
        }

        if (category.value == "") {
            category.style.border = "solid red 1px"
            setError(prev => "Select category")
            return
        }

        if (category.value != "") {
            category.style.border = "solid #dddd 1px"
        }

        if (subject.value == "") {
            subject.style.border = "solid red 1px"
            setError(prev => "Please enter subject")
            return
        }

        if (subject.value != "") {
            subject.style.border = "solid #dddd 1px"
        }

        if (message.value == "") {
            message.style.border = "solid red 1px"
            setError(prev => "Please enter your message")
            return
        }

        if (message.value != "") {
            message.style.border = "solid #dddd 1px"
        }

        setError(prev => "")

        window.location.href = "mailto:" + email + "?subject=" + subject.value + "&body=" + body
    }
    return (
        <div className="col-lg-10 col-12 mx-auto mt-5">
            <div className="row">
                <div className="col-12 col-md-6 order-last order-md-first">

                    <div class="form-group">
                        <label className='mb-2 bold' for="name">Name</label>
                        <input className="form-control" type="text" id="name" placeholder="Name"></input>
                    </div>

                    <div class="form-group mt-2">
                        <label className='mb-2 bold' for="name">School name</label>
                        <input className="form-control" type="text" id="schoolName" placeholder="School name"></input>
                    </div>

                    <div class="form-group mt-2">
                        <label className='mb-2 bold' for="name">Category</label>
                        <select id="category" className="form-control">
                            <option value="Student">Student</option>
                            <option value="School">School</option>
                            <option value="Complaint">Complaint</option>
                            <option value="Support">Support</option>
                        </select>
                    </div>

                    <div class="form-group mt-2">
                        <label className='mb-2 bold' for="name">Subject</label>
                        <input id="subject" className="form-control" type="text" placeholder="Subject"></input>
                    </div>

                    <div class="form-group mt-2">
                        <label className='mb-2 bold' for="name">Message</label>
                        <textarea style={{ minHeight: 150 }} id="message" className="form-control" placeholder="Your message here ..."></textarea>
                    </div>

                    <button onClick={send} className="btn primaryBtn text-white mt-2">Send</button>
                    <p className="text-danger my-2">{error}</p>
                </div>

                <div className="col-12 col-md-6">
                    <h1 className="bold">Contact Details</h1>
                    <p>Tel: <a href="tel:+27649429969">+27 64 942 9969</a></p>
                    <p>Email: <a href="mailto:support@ann-connect.com">support@ann-connect.com</a></p>
                    <p>Physical Address: Justice Mahomed St& Steve Biko Rd, Building 5, 0002 (Muckleneuk)</p>
                </div>
            </div>
        </div >
    )
}