import { ShareBlog } from "../../components/common/sharePost"
export const News1 = ({ id }) => {
    document.title = "Success of Pilot Program: Ann-Connect conducted a pilot test with students and a Lecturer from the University of South Africa(UNISA) "
    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Success of Pilot Program: Ann-Connect conducted a pilot test with students
                and a Lecturer from the University of South Africa (UNISA)</h1>
            <p>08 March 2023 | South Africa</p>

            <img className='mb-2' style={{ width: "100%" }} src="../img/blog1.jpg"></img>

            <p>Letter from UNISA Lecturer: <b>Nonceba Ntoyanto-Tyatyantsi</b></p>

            <p>This serves to confirm that Mr Bonginkosi Mabaso, representing the Ann-Connect
                project conducted a pilot testing with students from the University of South Africa
                (Unisa). Briefly, the project was selected by Unisa to conduct a pilot with the
                objective to test whether or not their technology would enable students to write
                online exams without an internet connection. I Nonceba Ntoyanto-Tyatyantsi,
                Lecturer from the Department of Applied Management was involved in the testing
                as a facilitator and scheduling exam questions for the students. The pilot was
                completed on 08th March 2023 and it was confirmed by Unisa students that they
                were able to write and submit online exams without the use of an internet
                connection.</p>

            <p>The Ann-Connect project completed the pilot using the resources below:</p>

            <p>
                <li>13 Unisa students</li>
                <li>Lecturer</li>
                <li>Mobile Application for students</li>
                <li>Administration Desktop System</li>
                <li>Examination type: Multiple Choice Questions</li>
            </p>

            <p>At the end of the pilot testing, the Ann-Connect project provided findings of the
                pilot to Unisa.</p>

            <p>Nonceba Ntoyanto-Tyatyantsi, Lecturer: Department of Applied Management</p>

            <ShareBlog url={"https://ann-connect.com/news/" + id} />
        </div>

    )
}