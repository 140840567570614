import { FaChevronDown } from "react-icons/fa";

export const FAQ3 = () => {
    return (
        <div className="col-12 col-md-6 my-2">
            <div data-bs-toggle="collapse" data-bs-target="#faq3" className="col-12 faqbox rounded py-2">
                <div className="row d-flex align-items-center">
                    <div className="col-10">
                        <p className="bold m-2">
                            Does the platform require students to be connected to the internet
                            to write their online exams?
                        </p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <FaChevronDown className="me-2" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" />
                    </div>
                </div>
                <div className="collapse" id="faq3">
                    <p className="mx-2">No, the platform allows students to access, write and
                        submit online exams without using internet connection. Please
                        book a session to watch a live demo of how the platform
                        works.</p>
                </div>
            </div>
        </div>
    )
}