import { Link } from "react-router-dom"
export const BookSession = () => {
    return (
        <div className="row mt-4">
            <div className="col-12 col-lg-10 mx-auto mt-5">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-6">
                        <img style={{ width: "100%" }} src="img/book.jpg"></img>
                    </div>
                    <div className="col-12 col-md-6">
                        <h1 className="bold">Book a Demo Session</h1>
                        <p>Interested in watching how the offline
                            platform works? Book a date and time
                            for the demo session. Our support team
                            will demonstrate the demo virtually with you.</p>
                        <Link onClick={() => window.scroll(0, 0)} to='/book'><button className="btn primaryBtn text-white">Book</button></Link>
                    </div>
                </div>
            </div>
        </div>

    )
}