
import { FAQ1 } from "../../content/faqs/faq1"
import { FAQ2 } from "../../content/faqs/faq2"
import { FAQ3 } from "../../content/faqs/faq3"
import { FAQ4 } from "../../content/faqs/faq4"
import { FAQ5 } from "../../content/faqs/faq5"
import { FAQ6 } from "../../content/faqs/faq6"
import { FAQ7 } from "../../content/faqs/faq7"
import { FAQ8 } from "../../content/faqs/faq8"
export const Faq = () => {
    return (
        <div className="col-12 col-lg-10 mx-auto mt-4">
            <div className="row">
                <h1 className="bold my-4">Frequently Asked Questions</h1>
            </div>

            <div className="row">
                <FAQ1 />
                <FAQ2 />
                <FAQ3 />
                <FAQ4 />
                <FAQ5 />
                <FAQ6 />
                <FAQ7 />
                <FAQ8 />
            </div>
        </div>
    )
}