import { NavBar } from "../common/nav"

export const HeaderCareer = () => {
    return (
        <div style={bg} className="col-12 header text-white">
            <div className="col-12 col-lg-10 mx-auto">
                <NavBar />
            </div>

            <div style={{ height: "100%", marginTop: -50 }} className="col-12 col-lg-10 mx-auto d-flex align-items-center">
                <div className="col-md-6 col-12">
                    <h1 className="bold">Why join the Ann-Connect Team?</h1>
                    <p>At Ann-Connect, we are looking for potential change makers
                        to think different and make use of innovations. We adopt Blue
                        Ocean a Strategy to create new markets and innovative ideas.
                        Join us today and make a difference in the world.</p>
                </div>
            </div>
        </div>
    )
}

const bg = {
    background: "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(img/careers.jpg) no-repeat center",
    backgroundSize: "cover"
}