import { useRef, useEffect } from "react"
import { FaInfo } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { MdReportProblem } from "react-icons/md";

export const About = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "about") {
            document.getElementById("about").scrollIntoView()
        }
    }, [])
    return (
        <div ref={myRef} id="about" style={{ background: "#0A4F92" }} className="col-12 text-white mt-4 py-5">
            <div className="col-12 col-lg-10 mx-auto ">
                <h1 className="text-center bold mb-5">ABOUT US</h1>
                <div className="row">
                    <div className="col-md-4 col-12">
                        <center><MdReportProblem size={50} className="mx-auto m-2" /></center>
                        <h5 className="bold text-center">Problem</h5>
                        <p className="text-center"> Students struggle to remain
                            connected throughout the online exam session due to Load-shedding,
                            unstable internet connection, and technical glitches</p>
                    </div>

                    <div className="col-md-4 col-12">
                        <center><FaInfo size={50} className="mx-auto m-2" /></center>
                        <h5 className="bold text-center">Solution</h5>
                        <p className="text-center">Ann-Connect built an offline fully integrated
                            platform that works without internet connection
                            and it is compatible with Phone,
                            Tablet, and Computer devices.</p>
                    </div>
                    <div className="col-md-4 col-12">
                        <center><FaGear size={50} className="mx-auto m-2" /></center>
                        <h5 className="bold text-center">Integration</h5>
                        <p className="text-center">The platform allows easy integration with third-party
                            systems: LMS or Moodle and Proctoring systems to
                            enhance the creation and accessibility of online
                            exams while mitigating potential risks of cheating.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}