import { JsonJob } from "../../json/jobs"
import { Link } from "react-router-dom"
import { FaLocationArrow } from 'react-icons/fa'
export const CareerJobs = () => {
    return (
        <div className="row">
            <h1 className="bold">{JsonJob.length} Opening positions</h1>
            {
                JsonJob.map((val, index) =>
                    <div key={index} className="col-12 mt-4">
                        <div className="row faqbox rounded py-2 d-flex align-items-center ">
                            <div className="col">
                                <Link className='text-dark' onClick={() => window.scroll(0, 0)} to={'/careers/' + (val.id)}><p className="bold">{val.name}</p></Link>
                            </div>
                            <div className="col">
                                <p>{val.department}</p>
                            </div>
                            <div className="col">
                                <p><FaLocationArrow /> {val.location}</p>
                            </div>
                            <div className="d-none d-md-flex col justify-content-end">
                                <Link onClick={() => window.scroll(0, 0)} to={'/careers/' + (val.id)}>View</Link>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )
}