import { FaChevronDown } from "react-icons/fa";

export const FAQ1 = () => {
    return (
        <div className="col-12 col-md-6 my-2">
            <div data-bs-toggle="collapse" data-bs-target="#faq1" className="col-12 faqbox rounded py-2">
                <div className="row d-flex align-items-center">
                    <div className="col-10">
                        <p className="bold m-2">Is the platform able to integrate with LMS such as Moodle?</p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <FaChevronDown className="me-2" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="false" />
                    </div>
                </div>
                <div className="collapse" id="faq1">
                    <p className="mx-2">Yes, currently, the platform uses Moodle as an LMS to
                        create and manage exams. The platform built an API that
                        allows integration with other LMS platforms.</p>
                </div>
            </div>
        </div>
    )
}