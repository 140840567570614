import { CareerJobs } from "./careerJobs"
export const CareerBody = () => {
    return (
        <div className="col-lg-10 col-12 mx-auto mt-5">
            <div className="row d-flex align-items-center mb-4">
                <div className="col-12 col-md-6">
                    <img style={{ width: "100%" }} src="img/career.jpg"></img>
                </div>

                <div className="col-12 col-md-6 mt-md-0 mt-4">
                    <h1 className="bold">Let’s Innovate Together</h1>
                    <p>How we change the world is how we make use of innovation.
                        The idea of creating an offline exam platform started in 2021
                        when our Founder: who was a student at the time, was
                        disconnected for 15 minutes before the start-time of the
                        online exam. Fast forward, Internet connectivity came back 25
                        minutes after the exam started – from that moment, he
                        thought “How can I create a platform that does not require
                        internet connection?”</p>

                    <p>Ever since, Ann-Connect employed change makers who
                        believed that there is a way an innovation can be developed
                        to function without connectivity. We need to people who are
                        willing to think outside the box, to challenge the norms, and
                        people who are eager to create what is deemed as
                        impossible. At Ann-Connect, we make the impossible, possible
                        and that makes us unique.</p>
                </div>
            </div>

            {/*   <div>
                <div className="col-12 mt-md-5">

                </div>
            </div> */}

            <CareerJobs />
        </div>
    )
}