import { CommonFooter } from "../components/common/commonFooter";
import { useParams } from "react-router-dom";
import { SubNavBar } from "../components/common/subNav";

export const PrivacyPage = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ background: "#002A52" }} className="col-12 mb-4">
          <div className="col-12 col-lg-10 mx-auto">
            <SubNavBar />
          </div>
        </div>
        <div className="col-12 col-lg-10 mx-auto">
          <h1 className="bold">ProctorPal Global Privacy Policy for Users</h1>

          <p>ProctorPal is a product developed and managed by Ann-Connect, registered under Company Registration Number 2021/621325/07 in the Republic of South Africa, is responsible for handling users' personal data.</p>

          <p>This notice explains how ProctorPal (" "we," "us") manages the personal data of individuals providing educational services through our App. It covers the types of data we collect, the reasons for processing it, our data protection measures, and your rights and choices as data subjects.</p>

          <p>
            <b>Please read this notice carefully if you are a user on the ProctorPal App.</b> It includes crucial information on how your personal data is processed and our requirements for handling users' data.
          </p>

          <p className="bold">1. Categories of Personal Data We Process</p>
          <p>We handle various categories of users' personal data, including:</p>
          <p>
            {" "}
            <li>General Data: Name, email, and phone number</li>
            <li>Geolocation Data: Users' locations collected via the ProctorPal app.</li>
            <li>Identification/Verification Data: user’s email address and photo.</li>
            <li>Communication Records: email chat messages, and customer service interactions telephonically.</li>
            <li>User-Generated Content: Audio recordings, pictures, , files submitted for customer support and user validation purposes.</li>
          </p>

          <p className="bold">2. Purposes of Data Processing</p>
          <p>We process users' personal data for various reasons, including:</p>
          <p>
            <li>Service Provision:. We collect users’ locations to ensure students are not writing an exam in the same location with an intent and objection to assist each other which violates legal requirements set by institutions, choose a school, and enroll for a module for ProctorPal to display relevant material associated with a specific school, and we also collect images and record audio during the invigilation process to analyze any flagged/unflagged sessions to prevent students from cheating in an exam.</li>
            <li>Compliance Checks: We verify user’s email address, phone number, photo, name, and surname to ensure the user who has registered on the App is the rightful owner of the account and the right user who is supposed to be proctored for an exam. This is to ensure we meet compliance and legal requirements set by institutions.</li>
            <li>Security and Legal Claims: Investigating and resolving disputes, ensuring App security, and complying with legal obligations.</li>
          </p>

          <p className="bold">3. Legal Basis for Processing</p>
          <p>Our primary legal bases for processing users' personal data are:</p>
          <p>
            <li>Performance of Contract: Necessary for using ProctorPal services, including identification and geolocation data processing.</li>
            <li>Legitimate Interest: Investigating fraud, ensuring security, and defending legal claims (excluding special categories of personal data).</li>
            <li>Legal Obligations: Complying with laws requiring data disclosure.</li>
            <li>Consent: Where explicitly given for specific purposes.</li>
          </p>

          <p className="bold">4. Data Sharing</p>
          <p>
            <li>With school educators: users' names, surnames, email addresses, student numbers, images captured and audio recordings collected during the invigilation session will be shared with school educators after the end of the session to validate any flagged/unflagged invigilation session.</li>
            <li>Legal and Regulatory Authorities: Data may be shared with authorities under court orders or for public interest tasks.</li>
          </p>

          <p className="bold">5. Security and Access</p>
          <p>Personal data is stored in data centres managed by our cloud hosting service provider, i.e., Amazon Web Services (AWS) and Google services. Access is restricted to authorized employees for specific purposes.</p>

          <p className="bold">6. Processing User Data</p>
          <p>School educators must not process users' personal data without ProctorPal's permission. Violation of this policy may result in account termination and liability for damages.</p>

          <p className="bold">7. Data Subject Rights</p>
          <p>Users have the right to:</p>
          <p>
            <li>Access: Request copies of their personal data.</li>
            <li>Correction: Request corrections of inaccurate data.</li>
            <li>Deletion: Request data erasure under certain conditions.</li>
            <li>Restriction: Request restricted data processing under specific conditions.</li>
            <li>Objection: Object to data processing based on legitimate interest.</li>
            <li>Data Portability: Request data transfer to another organization or directly to themselves.</li>
            <li>Withdraw Consent: Withdraw consent at any time, affecting future processing.</li>
          </p>

          <p className="bold">8. Retention Periods</p>
          <p>
            <li>Active Accounts: Personal data is stored as long as the users’ account is active.</li>
            <li>Closed Accounts: Data is retained for an additional three years post- account closure.</li>
            <li>Dispute-Related Data: Retained until claims are resolved.</li>
            <li>Email Messages: Retained for 90 days, or six months if related to a reported incident.</li>
            <li>Audio Recordings: Stored locally for 6 months for school educators to validate the data to maintain exam integrity</li>
            <li>Images captured during invigilation session: Stored locally for 6 months for school educators to validate the data to maintain exam integrity</li>
          </p>

          <p className="bold">9. Dispute Resolution</p>
          <p>Disputes regarding personal data processing can be addressed through customer support at support@ann-connect.com.</p>

          <p className="bold">10. Account deletion</p>
          <p>Users can send an email to support@ann-connect.com to request account deletion, which will erase all the personal data stored locally.</p>

          <p className="bold">10. Continuous Improvement</p>
          <p>We are committed to protecting your privacy and will continue to update this notice to enhance transparency and data protection practices.</p>
        </div>
        <CommonFooter />
      </div>
    </div>
  );
};
