import { Link } from "react-router-dom"

export const FounderMessage = () => {
    return (
        <div className="row mt-5">
            <div className="col-12 col-lg-10 mx-auto mb-5">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-6">
                        <img className='mb-5' style={{ width: "100%" }} src="img/dashboard.png"></img>
                    </div>
                    <div className="col-12 col-md-6">
                        <h1 className="bold">Explore offline Exam Packages</h1>
                        <p>The offline exam platform is built to be compatible
                            on three devices: Phone, Computer and Tablet.
                            Students can use the platform on a device of their
                            choosing. Explore the platform features by clicking
                            the button below.</p>
                        <Link reloadDocument to='/features'><button className="btn primaryBtn text-white ">Learn More</button></Link>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-10 mx-auto mt-5 mb-5">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-6">
                        <center>
                            <img className='mx-auto my-2 d-block d-md-none'
                                style={{ width: 100, height: 100, borderRadius: "100%" }}
                                src="/img/founder.jpg"></img>
                        </center>
                        <h1 className="bold text-center ">Founder’s Message</h1>
                        <p className="text-center " style={{ fontStyle: "italic" }}>“Ann-Connect built a platform that ensures
                            offline stable connectivity where students from all areas – locally and
                            internationally have equal access to quality education with uninterrupted
                            connectivity”</p> <p style={{ fontStyle: "unset" }} className="text-center bold">-Bonginkosi Mabaso</p>

                    </div>
                    <div className="d-none d-md-block col-6">
                        <img style={{ width: 250, height: 250, borderRadius: "100%" }} src="img/founder.jpg"></img>
                    </div>
                </div>
            </div>
        </div >

    )
}