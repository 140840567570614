import { ShareBlog } from "../../components/common/sharePost"
export const News2 = ({ id }) => {
    document.title = "Innovator behind the Innovation – Ann-Connect: Finalist of the international Blue Ocean Awards in France, 8th Edition 2023"
    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Innovator behind the Innovation – Ann-Connect: Finalist of the international
                Blue Ocean Awards in France, 8th Edition 2023</h1>
            <p>12 July 2022 | South Africa</p>

            <img className='mb-2' style={{ width: "100%" }} src="../img/blog2.jpg"></img>

            <p>Founder and CEO, Bonginkosi Mabaso fly the SA flag at the international Blue
                Ocean Awards (BOA) in France. “I am pleased that Ann-Connect was selected as
                one of the best innovations in South Africa to proceed with showcasing in France
                with other countries present: Senegal, and India”, said Bonginkosi Mabaso.</p>

            <p className="bold">The Founding and Traction of Ann-Connect.</p>

            <p>Ann-Connect started as an idea in 2021 November under Blue Ocean Corners to
                proceed with development of a Minimum Viable Product (MVP), supported by
                UNISA and Tshwane University of Technology (TUT). In March, 2023, Students from
                UNISA and TUT participated a pilot program which was a success. Students
                reported that they were able to access, write and submit their online
                examinations without using internet connection.</p>

            <p className="bold">Partnerships, Support and Awards</p>

            <p>Ann-Connect, supported by Unisa speaks to the UN’s Sustainable Development
                Goals (https://sdgs.un.org/goals). The start-up is being supported by the Start-
                Up Academy of the Unisa Directorate of Innovation, Technology Transfer and
                Commercialisation (DITTC) through training, mentorship and funding.</p>

            <p><i>“We as the Republic of South Africa are extremely proud of the South African
                delegation through the leadership of Unisa who have emerged in their tireless
                efforts,”</i> said Nthabiseng Makuwa, Minister Plenipotentiary representing the
                Ambassador of South Africa in France at the awards ceremony.</p>
            <ShareBlog url={"https://ann-connect.com/news/" + id} />
        </div>

    )
}