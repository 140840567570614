import { ShareBlog } from "../../components/common/sharePost";
import { Link } from "react-router-dom";

export const News5 = ({ id }) => {
  document.title =
    "Ann-Connect joins LIF Global Programme: Acceptance into Royal Academy of Engineering";
  return (
    <div className="col-12 col-lg-10 mx-auto">
      <h1 className="bold">
        Ann-Connect joins LIF Global Programme: Acceptance into Royal Academy of
        Engineering
      </h1>
      <p>12 December 2023 | South Africa</p>

      <img
        className="mb-2"
        style={{ width: "100%" }}
        src="../img/blog5.jpg"
      ></img>

      <p>
        The Ann-Connect is proud to announce that Royal Academy of Engineering
        has accepted Ann-Connect into the LIF Global Programme 2024. This comes
        with great excitement as we are stepping into a global market, gaining
        access to more opportunities in the EdTech market.
      </p>

      <p className="bold">
        Onboarding & UK Residential – Unlocking Opportunities
      </p>

      <p>
        Director & Founder, Bonginkosi Mabaso has been appointed as the
        representative to participate in the programme which will involve
        training, in-country pitch events and UK residential visit in 2024. We
        pass our many thanks to the Technology Innovation Agency (TIA) and the
        Royal Academy of Engineering for the platform to grow and have a
        positive contribution to social, and economic impact nationally and
        globally. We believe the programme will provide all the necessary
        skills, knowledge, and marketplace for Ann-Connect to continue growing
        and commercialising further in the UK.
      </p>

      <ShareBlog url={"https://ann-connect.com/news/" + id} />
    </div>
  );
};
