import { useRef, useEffect } from "react"
export const Partners = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "partners") {
            document.getElementById("partners").scrollIntoView()
        }
    }, [])

    return (
        <div id="partners" ref={myRef} className="col-12 mt-4 py-5">
            <div className="col-12 col-lg-10 mx-auto">
                <h1 className="bold">Partnerships & Stakeholders</h1>
                <div className="row">
                    <div className="col">
                        <img style={{ width: "100%" }} src="img/unisa.png"></img>
                    </div>
                    <div className="col">
                        <img style={{ width: "100%" }} src="img/blueOcean.png"></img>
                    </div>
                    <div className="col">
                        <img style={{ width: "100%" }} src="img/tut.png"></img>
                    </div>
                    <div className="col">
                        <img style={{ width: "100%" }} src="img/innovationHub.png"></img>
                    </div>
                </div>
            </div>
        </div>

    )
}