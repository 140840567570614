import { HeaderCareer } from "../components/careers/headerCareer";
import { CareerBody } from "../components/careers/body";
import { CommonFooter } from "../components/common/commonFooter";
import DocumentMeta from "react-document-meta";

export const Careers = () => {
  document.title = "Careers";
  const meta = {
    title: "Careers | Ann-connect",
    description:
      "Discover exciting opportunities and join our dynamic team! Explore diverse roles, growth paths, and a vibrant work culture. Start your journey towards a fulfilling career with us today.",
    canonical: "http://ann-connect.com/careers",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "annconnect,ann-connect,offline plartform,offline first app,offline lms,moodle lms,moodle,moodle offline,moodle plugin",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <HeaderCareer />
          <CareerBody />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
