import { HeaderContact } from "../components/contact/headerContact";
import { CommonFooter } from "../components/common/commonFooter";
import { ContactBody } from "../components/contact/body";
import DocumentMeta from "react-document-meta";

export const Contact = () => {
  document.title = "Contact Us";
  const meta = {
    title: "Contact Us | Ann-connect",
    description:
      "Get in touch with us effortlessly! Whether you have inquiries, feedback, or collaboration ideas, our team is ready to assist. Reach out to us and let's connect to address your needs.",
    canonical: "http://ann-connect.com/contact",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "annconnect,ann-connect,offline plartform,offline first app,offline lms,moodle lms,moodle,moodle offline,moodle plugin",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <HeaderContact />
          <ContactBody />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
