import { useRef, useEffect } from "react"
export const Packages = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "packages") {
            document.getElementById("packages").scrollIntoView()
        }
    }, [])

    return (
        <div id="packages" ref={myRef} className="row mt-5">
            <div className="col-12 col-lg-10 mx-auto mt-5">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-6">
                        <h1 className="bold">Software Package</h1>
                        <p className="bold">Easy-to-use and Integrate LMS or Moodle to create, access, and
                            submit online exams. </p>
                        <p>Fully integrated Offline Examination System (OES) enables students
                            to access, write and submit online exams without the use of an
                            internet connection. Explore our software packages available for
                            schools and students.</p>
                        {/* <button className="btn primaryBtn text-white">Learn More</button> */}
                    </div>

                    <div className="d-none d-md-block col-md-6">
                        <img style={{ width: "50%" }} src="img/package.png"></img>
                    </div>
                </div>
            </div>
        </div>

    )
}