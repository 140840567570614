import { Link } from "react-router-dom"
import { FaLocationArrow } from "react-icons/fa";

export const Job6 = ({ id }) => {
    const subject = 'Administrative Support specialist'
    const email = 'recruitment@ann-connect.com'
    const to = 'mailto:' + email + '?subject=' + subject
    document.title = subject

    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Administrative Support specialist</h1>
            <p>Administration</p>
            <p><FaLocationArrow /> Pretoria, South Africa</p>
            <p className="bold">Position Description</p>

            <p>Ann-Connect is seeking an Administrative Support Specialist to provide support in
                various administrative and clerical tasks within the organization. We’re looking for
                someone who is very adept in general clerical duties as you will take on various
                tasks on a day-to-day basis. You must also be proficient in handling office tools
                and most common computer applications. You must be good with interpersonal
                relations as you will work closely with colleagues and staff from other teams, as
                well as guests and clients. You must also be a good communicator as you will be
                dealing and interacting with managers and even third party providers.</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Take part in office management, particularly in doing most of the
                    general clerical tasks.</li>
                <li>Attend phone calls and answer inquiries or forward calls to the
                    appropriate department.</li>
                <li>Assisting and schools with offline examination inquiries including
                    support with quotations, and services and packages offered.</li>
                <li>Maintaining office activity logs, files and documents</li>
                <li>Performing routine data entry work</li>
                <li>Preparing materials, documents, reports and correspondence</li>
                <li>Answering emails in a professional and timely manner</li>
                <li>Communicating general information to clients and staff members</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Ability to multitask and be organized.</li>
                <li>Sufficient experience in administrative service support.</li>
                <li>Proficiency in MS Office including Word, Excel, Outlook, and PowerPoint.</li>
                <li>Proficient in office equipment including fax machines, copy machines,
                    projectors, modern phone system, and printers.</li>
                <li>Excellent communication skills.</li>
            </p>

            <p className="bold">Basic Qualifications:</p>

            <p><li>Bachelor’s degree in communications, education, business or related field
                may be preferred</li></p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Pretoria within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun
                    activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with
                disabilities) who seek the opportunity to help re-define the future of connectivity. If you feel certain that you are the perfect candidate for this position, then what
                are you waiting for? Apply and join our innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}