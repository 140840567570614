import { FaChevronDown } from "react-icons/fa";

export const FAQ6 = () => {
    return (
        <div className="col-12 col-md-6 my-2">
            <div data-bs-toggle="collapse" data-bs-target="#faq6" className="col-12 faqbox rounded py-2">
                <div className="row d-flex align-items-center">
                    <div className="col-10">
                        <p className="bold m-2">
                            Will students be able to use the platform with any invigilation platform?
                        </p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <FaChevronDown className="me-2" data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="false" />
                    </div>
                </div>
                <div className="collapse" id="faq6">
                    <p className="mx-2">Yes, the platform allows any invigilation platforms for
                        students to use as part of maintaining exam integrity. This is
                        an easy-to-use integration.</p>
                </div>
            </div>
        </div>
    )
}