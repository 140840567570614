import { Link } from "react-router-dom";
import { BsLinkedin, BsWhatsapp, BsYoutube, BsTwitter, BsFacebook } from "react-icons/bs";
export const HomeFooter = () => {
  return (
    <div style={{ background: "#0A4F92" }} className="col-12 text-white mt-5 py-4 mt-4">
      <div className="row">
        <div className="col-12 col-lg-10 mx-auto ">
          <div className="row">
            <div className="col-md-2 col-4">
              <p className="bold">Company</p>
              <p>
                <Link className="text-white" reloadDocument to="/about">
                  About Us
                </Link>
                <br />
                <Link onClick={() => window.scroll(0, 0)} className="text-white" to="/careers">
                  Careers
                </Link>
                <br />
                <Link onClick={() => window.scroll(0, 0)} className="text-white" to="/news">
                  News & Events
                </Link>
                <br />
                <Link className="text-white" reloadDocument to="/partners">
                  Partners
                </Link>
                <br />
                <Link onClick={() => window.scroll(0, 0)} className="text-white" to="/contact">
                  Contact Us
                </Link>
                <br />
                <Link onClick={() => window.scroll(0, 0)} className="text-white" to="/privacy">
                  Privacy and policy
                </Link>
              </p>
            </div>
            <div className="col-md-2 col-4">
              <p className="bold">Products</p>
              <p>
                <Link className="text-white" reloadDocument to="/packages">
                  Software Package{" "}
                </Link>
                <br />
                <Link onClick={() => window.scroll(0, 0)} className="text-white" to="/book">
                  Book Session
                </Link>
                <br />
                <Link className="text-white" reloadDocument to="/features">
                  Features
                </Link>{" "}
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-1 col-2">
              <Link to="https://za.linkedin.com/company/ann-connect">
                <BsLinkedin size={20} color="#fff" />
              </Link>
            </div>
            <div className="col-md-1 col-2">
              <BsWhatsapp size={20} color="#fff" />
            </div>
            <div className="col-md-1 col-2">
              <BsYoutube size={20} color="#fff" />
            </div>
            <div className="col-md-1 col-2">
              <BsTwitter size={20} color="#fff" />
            </div>
            <div className="col-md-1 col-2">
              <BsFacebook size={20} color="#fff" />
            </div>
          </div>
          <p>Ann-Connect is a platform powered by an offline network to enable access of online exams without the use of internet connection</p>
          <p>Ann-Connect &copy; 2022-{new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};
