import { Link } from "react-router-dom"
import { FaLocationArrow } from "react-icons/fa";

export const Job3 = ({ id }) => {
    const subject = 'Marketing Specialist'
    const email = 'recruitment@ann-connect.com'
    const to = 'mailto:' + email + '?subject=' + subject
    document.title = subject

    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Marketing Specialist</h1>
            <p>Sales & Marketing</p>
            <p><FaLocationArrow /> Pretoria, South Africa</p>

            <p className="bold">Position Description</p>

            <p>At Ann-Connect, we run marketing offense across strategic pillars, continuously
                developing opportunities to leverage and amplify the Ann-Connect brand in
                South Africa. Work is done in close collaboration with cross-functional teams,
                including local ops, media team, product, support, business development and
                comms team</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Strategy, creation and implementation of brand marketing campaigns
                    (ATL, BTL and experiential) to drive brand favourability and business growth
                    in France</li>
                <li>Manage multiple agencies (creative, social, media) to develop fully
                    integrated campaigns based on local market context and insights</li>
                <li>Ensure alignment of market activities with global brand strategy</li>
                <li>Help manage partnerships with external third-parties, figuring out ways in
                    which we can develop joint marketing activities and co-marketing plans.</li>
                <li>Partner with local agencies and global teams to develop and transcreation
                    world class consumer marketing campaigns from start to finish with cut-
                    through creative, intelligent media plans and strong measurement &
                    learnings</li>
                <li>Work with research and analytics teams for measuring the incremental
                    impact of campaigns to increase ROI of future marketing investments</li>
                <li>Identify uniquely marketing opportunities aligned with business priorities
                    and deliver campaigns or activations against them.</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Minimum 6+ years in consumer marketing or related experience with a
                    successful record of delivering work with measurable business results on
                    time and on budget</li>
                <li>Experience managing creative and media agency partners</li>
                <li>Experience with ATL and BTL campaigns, bringing to life a global positioning
                    to local markets</li>
                <li>Curiosity and creativity. You’re full of fresh ideas to promote the business
                    and elevate the Brand</li>
                <li>High bar for quality, productivity, and delivering results</li>
                <li>Strong leadership presence and interpersonal skills with the ability to
                    influence and motivate people at all levels across a broad variety of job
                    functions</li>
                <li>Excellent organizational skills to juggle many tasks without losing sight of
                    the highest priority items</li>
                <li>Self-motivation and drive on projects. Ability to work independently and/or
                    within a group setting to push projects to completion.</li>
                <li>Excellent written and verbal communication + presentation skills</li>
            </p>

            <p className="bold">Basic Qualifications</p>

            <p>
                <li>A bachelor's degree in marketing, advertising, business or a related field.</li>
            </p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Pretoria within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun
                    activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with
                disabilities) who seek the opportunity to help re-define the future of connectivity.
                If you feel certain that you are the perfect candidate for this position, then what
                are you waiting for? Apply and join our innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}