import React from "react";

//Router
import { createBrowserRouter } from "react-router-dom";

import { Home } from "../views/home";
import { Contact } from "../views/contact";
import { BookSession } from "../views/book";
import { News } from "../views/news";
import { Careers } from "../views/career";
import { NewsID } from "../views/newsView";
import { CareerView } from "../views/careerView";
import { PrivacyPage } from "../views/privacyPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <Home />,
  },
  ,
  {
    path: "/packages",
    element: <Home />,
  },
  ,
  {
    path: "/features",
    element: <Home />,
  },
  {
    path: "/partners",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/book",
    element: <BookSession />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/news/:id",
    element: <NewsID />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/careers/:id",
    element: <CareerView />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  {
    path: "*",
    element: <h1 className="bold m-5">404 page not found</h1>,
  },
]);
