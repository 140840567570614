import { NavBar } from "../common/nav"

export const HeaderContact = () => {
    return (
        <div style={bg} className="col-12 header text-white">
            <div className="col-12 col-lg-10 mx-auto">
                <NavBar />
            </div>

            <div style={{ height: "100%", marginTop: -50 }} className="col-12 col-lg-10 mx-auto d-flex align-items-center">
                <div className="col-md-6 col-12">
                    <h1 className="bold">Get in touch with us</h1>
                    <p>Ann-Connect is a private company established in South Africa, Headquartered in
                        Pretoria (Sunnyside).</p>
                </div>
            </div>
        </div>
    )
}

const bg = {
    background: "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(img/contact.jpg) no-repeat center",
    backgroundSize: "cover"
}