import { CommonFooter } from "../components/common/commonFooter";
import { useParams } from "react-router-dom";
import { SubNavBar } from "../components/common/subNav";

import { News1 } from "../content/news/news1";
import { News2 } from "../content/news/news2";
import { News3 } from "../content/news/news3";
import { News4 } from "../content/news/news4";
import { News5 } from "../content/news/news5";

export const NewsID = () => {
  const { id } = useParams();

  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ background: "#002A52" }} className="col-12 mb-4">
          <div className="col-12 col-lg-10 mx-auto">
            <SubNavBar />
          </div>
        </div>
        {id == 1 ? (
          <News1 id={id} />
        ) : id == 2 ? (
          <News2 id={id} />
        ) : id == 3 ? (
          <News3 id={id} />
        ) : id == 4 ? (
          <News4 id={id} />
        ) : id == 5 ? (
          <News5 id={id} />
        ) : (
          <div className="col-12 col-md-10 mx-auto">
            <h1 className="bold"> Not found</h1>
          </div>
        )}
        <CommonFooter />
      </div>
    </div>
  );
};
