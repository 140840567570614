import { CommonFooter } from "../components/common/commonFooter";
import { useParams } from "react-router-dom";
import { SubNavBar } from "../components/common/subNav";

import { Job1 } from "../content/jobs/job1";
import { Job2 } from "../content/jobs/job2";
import { Job3 } from "../content/jobs/job3";
import { Job4 } from "../content/jobs/job4";
import { Job5 } from "../content/jobs/job5";
import { Job6 } from "../content/jobs/job6";

export const CareerView = () => {
  const { id } = useParams();

  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ background: "#002A52" }} className="col-12 mb-4">
          <div className="col-12 col-md-10 mx-auto">
            <SubNavBar />
          </div>
        </div>
        {id == 1 ? (
          <Job1 id={id} />
        ) : id == 2 ? (
          <Job2 id={id} />
        ) : id == 3 ? (
          <Job3 id={id} />
        ) : id == 4 ? (
          <Job4 id={id} />
        ) : id == 5 ? (
          <Job5 id={id} />
        ) : id == 6 ? (
          <Job6 id={id} />
        ) : (
          <div className="col-12 col-md-10 mx-auto">
            <h1 className="bold">Not found</h1>
          </div>
        )}
        <CommonFooter />
      </div>
    </div>
  );
};
