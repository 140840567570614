import { ShareBlog } from "../../components/common/sharePost"
import { Link } from "react-router-dom"
export const News3 = ({ id }) => {
    document.title = "Introducing offline exam platform: Ann-Connect introduces a new offline exam platform for schools and students that works without internet connectivity."
    return (
        <div className="col-12 col-lg-10 mx-auto">
            <h1 className="bold">Introducing offline exam platform: Ann-Connect introduces a new offline exam
                platform for schools and students that works without internet connectivity.</h1>
            <p>20 November 2023 | South Africa</p>

            <img className='mb-2' style={{ width: "100%" }} src="../img/blog3.jpg"></img>

            <p className="bold">Launch of Ann-Connect – Offline Exam Platform</p>

            <p>Ann-Connect Founder and CEO, Bonginkosi Mabaso announced today to the staff
                and followers of Ann-Connect the launch of the OES platform. The platform is set
                to revolutionize how schools: Universities, Colleges, and Online High Schools
                should enhance accessibility of their online exams without using an internet
                connection.</p>

            <p className="bold">Problem with Internet Connection</p>

            <p>Since the occurrence of the global pandemic: COVID-19, the majority of schools
                adopted digital solutions to ensure the continuance of teaching, learning, and
                assessments through these virtual platforms. However, the virtual solutions did
                not entirely come to the rescue as the current issue of Load-shedding continues to have an
                impact on the accessibility of online education, i.e., causing network disconnection.</p>

            <p className="bold">Solution to Internet Connectivity</p>

            <p>Students reported that they fail to access and make a submission of their online
                examinations due to unavailability of internet connectivity, at times, they even get
                kicked out of the system during an exam session as a result of network
                disconnection. Other identified issues are technical glitches, and slow connectivity especially students from disadvantaged areas: rural and
                townships areas.</p>

            <p>In response to the issues raised by schools and students, Ann-Connect built an
                offline exam platform that enables students to access their online exams without
                the use of an internet connection. This platform is not designed to replace
                systems that are in place but to integrate with LMS tools in schools such as
                Moodle or Invigilation platforms. Students do not need connectivity to access,
                write, and submit their online exams when using the Ann-Connect platform.</p>

            <p>We believe Ann-Connect is the solution schools have been looking for to solve
                internet connectivity issues. To learn more about the platform, click on this <Link to='../features'>link</Link>.</p>

            <ShareBlog url={"https://ann-connect.com/news/" + id} />
        </div>

    )
}