import { HeaderHome } from "../components/home/headerHome";
import { FounderMessage } from "../components/home/founderMessage";
import { About } from "../components/home/aboutUs";
import { PlatformFeatures } from "../components/home/features";
import { Packages } from "../components/home/packages";
import { BookSession } from "../components/home/bookSession";
import { Partners } from "../components/home/parners";
import { Faq } from "../components/home/faq";
import { HomeFooter } from "../components/home/homeFooter";
import { useLocation } from "react-router-dom";
import DocumentMeta from "react-document-meta";

export const Home = () => {
  document.title = "Ann-Connect";
  let location = useLocation();
  let path = location.pathname.substring(1);

  const meta = {
    title: "Ann-connect | Offline LMS",
    description:
      "Download | Offline Exam Platform | Official Ann-Connect Site",
    canonical: "http://ann-connect.com/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "annconnect,ann-connect,offline plartform,offline first app,offline lms,moodle lms,moodle,moodle offline,moodle plugin",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <HeaderHome />
          <FounderMessage />
          <About section={path} />
          <PlatformFeatures section={path} />
          <Packages section={path} />
          <BookSession />
          <Partners section={path} />
          <Faq />
          <HomeFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
